import React from "react";
import { BlogEntryItemMeta } from "../../utils/types/BlogEntryItem";
import { isLoggedIn } from "../../utils/types/User";
import { useCrud } from "../../hooks/CrudHook";
import DbBlog from "../../utils/types/DbBlog";
import { useNavigate } from "react-router-dom";

interface BlogEntryMetaProps {
    entry: BlogEntryItemMeta;
    id: number;
    urlId: string;
}

const BlogEntryMeta: React.FC<BlogEntryMetaProps> = ({ entry, id, urlId }) => {
    const { remove } = useCrud<DbBlog>({ endpoint: "articles" });
    const navigate = useNavigate();
    const handleDelete = async () => {
        await remove(id);
        navigate("/aktuality");
        navigate(0);
    }

    const url = `/edit-blog/${urlId}`;

    if (isLoggedIn()) {
        return (
            <div className="entry-meta">
                {/* <span>By: <a href={entry.author.url}>{entry.author.name}</a></span> */}
                <span>{entry.date}</span>
                <span><a href={url}><button className="theme-btn">Edit</button></a></span>
                <span><button onClick={handleDelete} className="theme-btn-s2">Delete</button></span>
            </div>
        );
    }
    else {
        return (
            <div className="entry-meta">
                {/* <span>By: <a href={entry.author.url}>{entry.author.name}</a></span> */}
                <span>{entry.date}</span>
            </div>
        );
    }
}

export default BlogEntryMeta;
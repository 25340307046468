import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useCrud } from '../../../hooks/CrudHook';
import { useNavigate } from 'react-router-dom';
import DbBlog from '../../../utils/types/DbBlog';
import Unauthorized from '../../Errors/Unauthorized';
import { isLoggedIn } from '../../../utils/types/User';
import { BlogEntryItem } from '../../../utils/types/BlogEntryItem';
import "./BlogForm.css"

interface AddBlogProps {
    id?: number;
}

const BlogForm: React.FC<AddBlogProps> = ({ id }) => {
    const { create, update, uploadImage } = useCrud<DbBlog>({ endpoint: "articles" });
    const navigate = useNavigate();
    const [title, setTitle] = useState<string>("");
    const [image, setImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string>("");
    const [publishBlog, setPublishBlog] = useState<boolean>(false);
    const [removeImage, setRemoveImage] = useState<boolean>(false);
    const [description, setDescription] = useState<string>("");
    const [blogContent, setBlogContent] = useState<string>('');
    const [selectedLanguage, setSelectedLanguage] = useState<string>("cz");

    useEffect(() => {
        const data = localStorage.getItem("blogs");
        if (!data) {
            console.log("No blogs!");
            return;
        }

        const blogs: BlogEntryItem[] = JSON.parse(data);
        if (id !== undefined) {
            const blogEntry = blogs.find((b: BlogEntryItem) => b.id == id);
            if (blogEntry) {
                setTitle(blogEntry.blog.title);
                setDescription(blogEntry.blog.description);
                setBlogContent(blogEntry.blog.content);
                setPublishBlog(blogEntry.meta.publish);
                setSelectedLanguage(blogEntry.meta.language);
                if (blogEntry.blog.images && !removeImage) {
                    setImageUrl(blogEntry.blog.images![0].url)
                }
            } else {
                navigate("/404");
            }
        }
    }, [id, navigate]);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            ['clean']
        ],
    };

    const formats = [
        'header', 'bold', 'italic', 'underline', 'strike',
        'blockquote', 'list', 'bullet', 'indent', "link"
    ];

    const CreateHeader: React.FC = () => {
        return id !== undefined ? <h2>Edit blog post</h2> : <h2>Add new blog post</h2>;
    }

    const handlePublishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPublishBlog(e.target.checked);
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setImage(e.target.files[0]);
            setRemoveImage(false);
        }
    };

    const handleContentChange = (value: string) => {
        setBlogContent(value);
    };

    const handleCancel = () => {
        if (id === undefined) {
            navigate("/blog");
        }
        else {const name = `${
            title
                .toLocaleLowerCase()
                .replaceAll(" ", "-")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            }`
        const urlId = `${id}/${name}`;
            navigate(`/blog/${urlId}`);
        }
    }

    const handleRemoveImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setImageUrl("");
        setRemoveImage(true);
        setImage(null);
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        let newImageUrl = imageUrl;
        if (image) {
            newImageUrl = `https://visionwebmedia.cz${await uploadImage(image)}`;
        } else if (removeImage) {
            newImageUrl = "";
        }

        const dbBlog: DbBlog = {
            id: id !== undefined ? id.toString() : '',
            title,
            header: "",
            completed: "",
            created_at: "",
            updated_at: "",
            user_id: "",
            description,
            category: "blog",
            content: blogContent,
            author: "admin",
            publish: publishBlog.toString(),
            published_date: "",
            image_data: newImageUrl,
            image_alt: "",
            url: "",
            language: selectedLanguage
        }

        if (id === undefined) {
            await create(dbBlog);
            navigate("/blog");
        } else {
            await update(id, dbBlog);
            navigate(`/blog`);
        }
    };

    const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLanguage(e.target.value);
    };

    if (isLoggedIn()) {
        return (
            <div className='container' id="rtf-row">
                <div className="col col-lg col-md col-sm">
                    <div className="container" id="blog-container">
                        <div className='row section-title-s3'>
                            <CreateHeader />
                        </div>
                        <form>
                            <div className="mb-3 row">
                                <label htmlFor="blogTitle" className="col-sm-2 col-form-label">Title</label>
                                <div className="col-sm-4">
                                    <div className="form-group row">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="blogTitle"
                                            placeholder="Title" 
                                            value={title} 
                                            onChange={handleTitleChange} 
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="blogPublish" className="col-sm-2 col-form-label">Publish</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            id="blogPublish"
                                            checked={publishBlog}
                                            onChange={handlePublishChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="blogDesc" className="col-sm-2 col-form-label">Description</label>
                                    <div className="col-sm-4">
                                        <textarea
                                            id="blogDesc"
                                            placeholder="Description"
                                            value={description}
                                            onChange={handleDescChange} 
                                        />
                                    </div>
                                    <label htmlFor="currentImage" className="col-sm-2 col-form-label">Current Image</label>
                                    <div className="col-sm-4">
                                        <img src={imageUrl}></img>
                                        <button
                                            id="currentImage"
                                            onClick={handleRemoveImage}
                                        >Remove</button>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="blogImage" className="col-sm-2 col-form-label">Image</label>
                                    <div className="col-sm-4">
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            id="blogImage" 
                                            onChange={handleImageChange} 
                                        />
                                    </div>
                                    <div className='col-sm-4'>Images should be in 1200x683 resolution.<br></br> <a href="https://www.online-image-editor.com/">Online Image Editor</a></div>
                                </div>
                            </div>
                            <div className="form-group row">
                                    <label htmlFor="blogLanguage" className="col-sm-2 col-form-label">Language</label>
                                    <div className="col-sm-4">
                                        <select
                                            id="blogLanguage"
                                            value={selectedLanguage}
                                            onChange={handleLanguageChange}
                                            className="form-control"
                                        >
                                            <option value="cz">Czech</option>
                                            <option value="en">English</option>
                                            <option value="zh">Chinese</option>
                                        </select>
                                    </div>
                                </div>
                            <ReactQuill
                                value={blogContent}
                                onChange={handleContentChange}
                                modules={modules}
                                formats={formats}
                            />
                            <button className="btn theme-btn" onClick={handleSave}>Save</button>
                            <button className="btn theme-btn-s2" onClick={handleCancel}>Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Unauthorized />;
    }
};

export default BlogForm;

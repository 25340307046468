import React from "react";

const Unauthorized: React.FC = () => {
    return (
        <div className="not-found">
            <section className="error-404-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="content clearfix">
                                <div className="error">
                                    <h2>401</h2>
                                </div>
                                <div className="error-message">
                                    <h3>Oops! You are not logged in!</h3>
                                    <p>We're sorry but this page is for logged in users only. Please go to the login page.</p>
                                    <a href="/login" className="theme-btn">Login</a>
                                    <a href="/" className="theme-btn">Back to home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Unauthorized;
import React from "react";
import PageTitle from "../components/PageTitle";
import Login from "../components/Login/Login";

import "./LoginPage.css";

const LoginPage: React.FC = () => {
    return (
        <div>
            <PageTitle pageName="Admin Login" desc="" id="login-page"/>
            <Login />
        </div>
    );
}

export default LoginPage;
import React from "react";
import PageTitle from "../components/PageTitle";

import "./BlogPage.css";
import BlogTypeMapper from "../utils/BlogTypeMapper";
import { BlogEntryItem, BlogEntryProps } from "../utils/types/BlogEntryItem";
import { BlogThumbnailType } from "../utils/enums/BlogThumbnailType";
import MapBlogFromDbToUi from "../utils/mappers/BlogMapper";
import { useCrud } from "../hooks/CrudHook";
import DbBlog from "../utils/types/DbBlog";
import { isLoggedIn } from "../utils/types/User";
import { SiteLanguage } from "../utils/enums/SiteLanguage";

interface BlogPageProps {
    language: SiteLanguage
}

const BlogPage: React.FC<BlogPageProps> = ({ language }) => {
    const { data, loading } = useCrud<DbBlog>({ endpoint: "articles" });
    let languageCode = "";
    switch (language) {
        case SiteLanguage.Chinese:
            languageCode = "zh"
            break;
        case SiteLanguage.English:
            languageCode = "en";
            break;
        default:
            languageCode = "cz";
            break;
    }
    let dbBlogs = MapBlogFromDbToUi(data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
    if (!isLoggedIn()) {
        dbBlogs = dbBlogs.filter(b => (b.meta.publish == true && b.blog.category == "blog" && b.meta.language == languageCode));
    }
    else {
        dbBlogs = dbBlogs.filter(b => (b.blog.category == "blog" && b.meta.language == languageCode));
    }
    if (dbBlogs.length > 0) {
        localStorage.removeItem("blogs");
        localStorage.setItem("blogs", JSON.stringify(dbBlogs));
    }
    if (loading) {
        return (
            <div>Loading ...</div>
        );
    }
    else {
        return (
            <div>
                <PageTitle pageName="" desc="" id="blog"/>
                <section className="blog-pg-section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col col-md">
                                <div className="blog-content">
                                    {dbBlogs.map((item, index) => {
                                        let entry: BlogEntryProps = {
                                            entry: item,
                                            inList: false,
                                            thumbnailType: BlogThumbnailType.Image
                                        }
                                        return (
                                            <BlogTypeMapper key={index} item={entry} />
                                        )
                                    })}
                                </div>
                                {/*
                                TODO: need to figure out pagination once BE is done 
                                <PaginationWrapper /> 
                                */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default BlogPage;
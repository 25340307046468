import React from "react";
import SocialNewsletter from "./SocialNewsletter";
import UpperFooter from "./UpperFooter";
import LowerFooter from "./LowerFooter";
import "./Footer.css"
import { SiteLanguage } from "../../utils/enums/SiteLanguage";

interface FooterProps {
    language: SiteLanguage
}

const Footer: React.FC<FooterProps> = ({ language }) => {
    return (
        <footer className="site-footer">
            <UpperFooter language={language} />
        </footer>
    );
}

export default Footer;
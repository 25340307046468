import React from "react";
import { Image } from "../../utils/types/Image";
import { Link } from "../../utils/types/Link";

interface FeatureItemProps {
    icon?: string;
    header: string;
    text: string;
    image?: Image;
    link?: Link;
    className: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, header, text, image, link, className }) => {
    return (
        <div className="col col-lg-3 col-sm-6">
            <div className={`feature-grid ${className}`}>
                {image && <img src={image.url} alt={image.alt}></img>}
                {icon && <i className={icon}></i>}
                <h2>{header}</h2>
                {link && <a href={link.url} className="read-more">{link.linkName}</a>}
            </div>
        </div>
    )
}

export default FeatureItem;
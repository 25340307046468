import React from "react";
import { BlogEntryProps } from "../../utils/types/BlogEntryItem";
import BlogEntryMeta from "./BlogEntryMeta";

const BlogEntryVideo: React.FC<BlogEntryProps> = ({ entry }) => {
    const urlBase = "/aktuality"
    const urlId = `${entry.id}/${entry.blog.title.toLocaleLowerCase().replaceAll(" ", "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`;
    const url = `${urlBase}/${urlId}`
    return (
        <div className="post format-video">
            <div className="entry-media video-holder">
                <img src={entry.blog.images![0].url} alt={entry.blog.images![0].alt}/>
                <a href={entry.blog.videoUrl!} className="video-btn" data-type="iframe">
                    <i className="fi flaticon-play-button"></i>
                </a>
            </div>
            <div className="entry-details">
                <div className="cat">{entry.blog.category}</div>
                <h3><a href={url}>{entry.blog.title}</a></h3>
                <p>{entry.blog.description}</p>
                <a href={url} className="read-more">Read More</a>
            </div>
            <BlogEntryMeta entry={entry.meta} id={entry.id} urlId={urlId}/>
        </div>
    );
}

export default BlogEntryVideo;
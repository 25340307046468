import FeatureSection from '../components/FeatureSection/FeatureSection';
import HeroSlider from '../components/HeroSlider/HeroSlider';

import { useCrud } from '../hooks/CrudHook';
import DbBlog from '../utils/types/DbBlog';
import MapBlogFromDbToUi from '../utils/mappers/BlogMapper';
import TeamSection from '../components/TeamSection/TeamSection';
import ServiceSectionGrid from '../components/ServiceSection/Grid/ServiceSectionGrid';
import AboutSection from '../components/AboutSection/AboutSection';
import Footer from '../components/Footer/Footer';
import { SiteLanguage } from '../utils/enums/SiteLanguage';
import SingleContentSection from '../components/Content/SingleContentSection';

interface HomePageProps {
    language: SiteLanguage
}

const HomePage: React.FC<HomePageProps> = ({language}) => {
    const { data, loading } = useCrud<DbBlog>({ endpoint: "articles" });
    console.log(data);
    if (data) {
        let dbBlogs = MapBlogFromDbToUi(data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
        const mainArticles = dbBlogs.filter(b => (b.blog.category == 'mainContent'));
        localStorage.removeItem("main-articles");
        localStorage.setItem("main-articles", JSON.stringify(mainArticles));
    }
    if (loading) {
        return (
            <div>Loading ...</div>
        );
    }
    else {
        return (
            <div className="page-wrapper">
                <FeatureSection language={language} />
                <AboutSection language={language} />
                <TeamSection language={language} />
                <ServiceSectionGrid language={language} />
                <HeroSlider language={language} />
                <SingleContentSection title={''} text={''} />
                <Footer language={language} />
            </div>
        );
    }
}

export default HomePage;
import React from "react";
import ServiceSectionGridItem from "./ServiceSectionGridItem";
import { Image } from "../../../utils/types/Image";
import billaLogo from "../../../assets/little-big-studio/company-logos/Billa-logo.png";
import herbarLogo from "../../../assets/little-big-studio/company-logos/herbar-1696847394752.png";
import srovnejToLogo from "../../../assets/little-big-studio/company-logos/Logo_srovnejto.cz.png";
import ravakLogo from "../../../assets/little-big-studio/company-logos/RAVAK-logo-23.png";
import primaLogo from "../../../assets/little-big-studio/company-logos/Receptar-prima-napadu_logo-2023.png";
import vitanaLogo from "../../../assets/little-big-studio/company-logos/Vitana.png";
import emcoLogo from "../../../assets/little-big-studio/company-logos/emco.png";
import etaLogo from "../../../assets/little-big-studio/company-logos/eta-logo.png";
import jihlavankaLogo from "../../../assets/little-big-studio/company-logos/jihlavanka.png";
import zonkyLogo from "../../../assets/little-big-studio/company-logos/logo-zonky.png";
import vodKurLogo from "../../../assets/little-big-studio/company-logos/vod-kur-logo.png";
import notinoLogo from "../../../assets/little-big-studio/company-logos/notino_logo_RGB.png";
import { SiteLanguage } from "../../../utils/enums/SiteLanguage";
import { languageMutations } from "../../../utils/constants/language-mutations";

interface ServiceSectionGridProps {
    language: SiteLanguage;
}

const ServiceSectionGrid: React.FC<ServiceSectionGridProps> = ({ language }) => {

    let title = languageMutations.cz.whatWeFilmed;

    if (language === SiteLanguage.English) {
        title = languageMutations.en.whatWeFilmed;
    }

    if (language === SiteLanguage.Chinese) {
        title = languageMutations.zh.whatWeFilmed;
    }

    const billaImage: Image = {
        url: billaLogo,
        alt: "billa-logo"
    }

    const herbarImage: Image = {
        url: herbarLogo,
        alt: "herbar-logo"
    }

    const srovnejToImage: Image = {
        url: srovnejToLogo,
        alt: "srovnej-to-logo"
    }

    const ravakImage: Image = {
        url: ravakLogo,
        alt: "ravak-logo"
    }

    const primaImage: Image = {
        url: primaLogo,
        alt: "receptar-prima-logo"
    }

    const vitanaImage: Image = {
        url: vitanaLogo,
        alt: "vitana-logo"
    }

    const emcoImage: Image = {
        url: emcoLogo,
        alt: "emco-logo"
    }

    const etaImage: Image = {
        url: etaLogo,
        alt: "eta-logo"
    }

    const jihlavankaImage: Image = {
        url: jihlavankaLogo,
        alt: "jihlavanka-logo"
    }

    const zonkyImage: Image = {
        url: zonkyLogo,
        alt: "zonky-logo"
    }

    const vodKurImage: Image = {
        url: vodKurLogo,
        alt: "vodnanske-kure-logo"
    }

    const notinoImage: Image = {
        url: notinoLogo,
        alt: "notino-logo"
    }

    return (
        <section className="service-section-s2 service-pg-service-section-s2" id="co-jsme-tocili">
            <div className="container">
            <div className="row">
                    <div className="col col-lg-6 col-md-8 col-sm-10">
                        <div className="section-title-s3">
                            
                        </div>
                        <h2>{title}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="service-grids">
                            <ServiceSectionGridItem img={billaImage} link="https://visionwebmedia.cz/uploads/lbs/billa5s.mov" title="" />
                            <ServiceSectionGridItem img={emcoImage} link="https://visionwebmedia.cz/uploads/lbs/emco5s.mov" title="" />
                            <ServiceSectionGridItem img={etaImage} link="https://visionwebmedia.cz/uploads/lbs/eta5s.mov" title="" />
                            <ServiceSectionGridItem img={herbarImage} link="https://visionwebmedia.cz/uploads/lbs/herbar5s.mov" title="" />
                            <ServiceSectionGridItem img={jihlavankaImage} link="https://visionwebmedia.cz/uploads/lbs/jihlavanka5s.mov" title="" />
                            <ServiceSectionGridItem img={primaImage} link="https://visionwebmedia.cz/uploads/lbs/receptar5s.mov" title="" />
                            <ServiceSectionGridItem img={ravakImage} link="https://visionwebmedia.cz/uploads/lbs/ravak5s.mov" title="" />
                            <ServiceSectionGridItem img={srovnejToImage} link="https://visionwebmedia.cz/uploads/lbs/srovnejto5s.mov" title="" />
                            <ServiceSectionGridItem img={vitanaImage} link="https://visionwebmedia.cz/uploads/lbs/vitana5s.mov" title="" />
                            <ServiceSectionGridItem img={vodKurImage} link="https://visionwebmedia.cz/uploads/lbs/VK5s.mov" title="" />
                            <ServiceSectionGridItem img={zonkyImage} link="https://visionwebmedia.cz/uploads/lbs/zonky5s.mov" title="" />
                            <ServiceSectionGridItem img={notinoImage} link="https://visionwebmedia.cz/uploads/lbs/notino5s.mov" title="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceSectionGrid;
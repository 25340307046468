import { useState } from 'react';
import axios from 'axios';

interface AuthContextProps {
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

export const useAuth = (): AuthContextProps => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const login = async (email: string, password: string) => {
        try {
            const response = await axios.post('https://visionwebmedia.cz/users/sign_in', { headers: { "Content-Type": "application/json"
            }, "user": { email, password }});
            if (response.status === 200) {
                setIsAuthenticated(true);
                // Optionally, store the token in localStorage or a cookie
                localStorage.setItem('token', response.headers.authorization);
            }
        } catch (error) {
            console.error('Login failed:', error);
            setIsAuthenticated(false);
        }
    };

    const logout = () => {
        setIsAuthenticated(false);
        // Optionally, remove the token from localStorage or a cookie
        localStorage.removeItem('token');
    };

    return {
        isAuthenticated,
        login,
        logout,
    };
};
import React, { useState } from 'react';
import { useAuth } from '../../hooks/AuthHook';

import "./Login.css";
import { isLoggedIn } from '../../utils/types/User';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login, logout } = useAuth();

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await login(username, password);
    };

    const handleLogout = async () => {
        await logout();
    }
    if (isLoggedIn()) {
        return (
            <section className="service-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-8 col-md-push-2">
                            <div className="service-single-content">
                                <h1>Welcome!</h1>
                                <a href="/login"><button type="submit" className="theme-btn" onClick={handleLogout}>Logout</button></a>
                                <a href="/add-blog"><button type="submit" className="theme-btn">Add Blog</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    else {
        return (
            <div className="container">
                <form className='login-form' id="loginform" onSubmit={handleLogin}>
                    <div className='form-inputs'>
                        <div className='col-md-6'>
                            <label>Username:</label> <br/>
                            <input type="email" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="col-md-6">
                            <label>Password: </label> <br/>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                    </div>
                    <a href="/login"><button type="submit" className="theme-btn">Login</button></a>
                </form>
            </div>
        );
    }
};

export default Login;

import React from "react";
import aboutImage from "../../assets/little-big-studio/interior-pictures/fotka3.png"
import "./AboutSection.css";
import { SiteLanguage } from "../../utils/enums/SiteLanguage";
import { languageMutations } from "../../utils/constants/language-mutations";

interface AboutSectionProps {
    language: SiteLanguage
}

const AboutSection:React.FC<AboutSectionProps> = ({ language }) => {
    let about = languageMutations.cz.about;
    let title = languageMutations.cz.whatWeDo;

    if (language === SiteLanguage.English) {
        about = languageMutations.en.about;
        title = languageMutations.en.whatWeDo;
    }

    if (language === SiteLanguage.Chinese) {
        about = languageMutations.zh.about;
        title = languageMutations.zh.whatWeDo;
    }

    return (
        <section className="about-section section-padding" id="co-delame">
            <div className="container">
                <div className="row">
                    <div className="col col-md-8">
                        <div className="left-col">
                            <div className="section-title">
                                <h1>{title}</h1>
                                <div dangerouslySetInnerHTML={{__html: about}}></div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-md-4" id="about-image">
                        <div className="mid-col">
                            <img src={aboutImage}></img>
                        </div>
                    </div>
                    {/* <div className="col col-md-4">
                        <div className="mid-col">
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam lectus justo, vulputate eget mollis sed, tempor sed magna. Donec iaculis gravida nulla. Integer vulputate sem a nibh rutrum consequat. Ut tempus purus at lorem. Maecenas libero.</p>

                            <div className="quoter">
                                <h4>Michael Scott</h4>
                                <span>- Branch manager</span>
                            </div>
                            <div className="signature">
                                <img src={require("../../assets/images/michael-scott-sign.png")}></img>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default AboutSection;
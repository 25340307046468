import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "./TeamSection.css";
import img1 from "../../assets/little-big-studio/people-photos/oie_26113254ICAKBpCg.jpg";
import img2 from "../../assets/little-big-studio/people-photos/oie_26114043yus0NEWN.jpg";
import img3 from "../../assets/little-big-studio/people-photos/oie_38240jRqPCZrs.jpg";
import img4 from "../../assets/little-big-studio/people-photos/photo.png";
import img5 from "../../assets/little-big-studio/people-photos/oie_3843JxMSJE2y.jpg";
import HeroSlideHover from "../HeroSlider/HeroSlideHover";
import { SiteLanguage } from "../../utils/enums/SiteLanguage";
import { languageMutations } from "../../utils/constants/language-mutations";

interface TeamSectionProps {
    language: SiteLanguage
}

const TeamSection: React.FC<TeamSectionProps> = ({ language }) => {

    let showNavigation = true;

    let headerText = "Kdo jsme?"
    if (language === SiteLanguage.English) {
        headerText = languageMutations.en.whoWeAre;
    }
    if (language === SiteLanguage.Chinese) {
        headerText = languageMutations.zh.whoWeAre;
    }

    return (
        <section className="team-section section-padding" id="kdo-jsme">
            <div className="container" id="team-container">
                <div className="row">
                    <div className="col col-lg-6 col-md-8 col-sm-10">
                        <div className="section-title-s3"></div>
                        <h2>{headerText}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="team-grids team-slider">
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                loop={true}
                                centeredSlides={false}
                                slidesPerView={3}
                                spaceBetween={40}
                                navigation={showNavigation}
                                className="swiper-container"
                                breakpoints={{
                                    // When the window width is >= 1024px (desktop)
                                    991: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                    },
                                    // When the window width is >= 768px (tablet)
                                    667: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    // When the window width is <= 767px (mobile)
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <HeroSlideHover
                                        background={img5}
                                        dataText=""
                                        titleText="Honza<br>Hytha"
                                        slideText="<p>CO-FOUNDER<br>CEO<br>COLOR GRADING</p>"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HeroSlideHover
                                        background={img2}
                                        dataText=""
                                        titleText="Honza<br>Kosík"
                                        slideText="<p>CO-FOUNDER<br>CTO<br>DATA MANAGEMENT</p>"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HeroSlideHover
                                        background={img3}
                                        dataText=""
                                        titleText="Filip<br>Kněžínek"
                                        slideText="<p>SENIOR EDITOR</p>"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HeroSlideHover
                                        background={img1}
                                        dataText=""
                                        titleText="Matej<br>Šram"
                                        slideText="<p>SENIOR EDITOR<br></br>MOTION GRAPHIC<br>EDITING SUPERVISOR</p>"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HeroSlideHover
                                        background={img4}
                                        dataText=""
                                        titleText="Dream Team"
                                        slideText="<p></p>"
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamSection;

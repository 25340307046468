import React from "react";
import { SiteLanguage } from "../../utils/enums/SiteLanguage";
import { languageMutations } from "../../utils/constants/language-mutations";
import fb from "../../assets/little-big-studio/icons/FB.png";
import ig from "../../assets/little-big-studio/icons/IG.png";

interface UpperFooterProps {
    language: SiteLanguage
}

const UpperFooter: React.FC<UpperFooterProps> = ({ language }) => {
    type Item = [string, string | null];

    interface Link {
        url: string,
        linkName: string
    }

    const leftLinks1: Link[] = [
        {
            linkName: `Telefonní číslo:
                    +420 702 162 829

                    Email:
                    produkce@littlebigstudio.cz`,
            url: ""
        },
        {
            linkName: "Založenie spoločnosti",
            url: ""
        },
    ]

    let title = languageMutations.cz.contacts;
    let companyId = languageMutations.cz.companyId;
    let taxId = languageMutations.cz.taxId;
    let phoneNumber = languageMutations.cz.phoneNumber;
    let email = languageMutations.cz.email;
    let openingHoursLabel = languageMutations.cz.openingHoursLabel;
    let openingHoursText = languageMutations.cz.openingHoursText;

    if (language === SiteLanguage.English) {
        title = languageMutations.en.contacts;
        companyId = languageMutations.en.companyId;
        taxId = languageMutations.en.taxId;
        phoneNumber = languageMutations.en.phoneNumber;
        email = languageMutations.en.email;
        openingHoursLabel = languageMutations.en.openingHoursLabel;
        openingHoursText = languageMutations.en.openingHoursText;
    }

    if (language === SiteLanguage.Chinese) {
        title = languageMutations.zh.contacts;
        companyId = languageMutations.zh.companyId;
        taxId = languageMutations.zh.taxId;
        phoneNumber = languageMutations.zh.phoneNumber;
        email = languageMutations.zh.email;
        openingHoursLabel = languageMutations.zh.openingHoursLabel;
        openingHoursText = languageMutations.zh.openingHoursText;
    }

    return (
        <div className="upper-footer" id="kontakty">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                        <div className="widget about-widget">
                            <div className="widget-title">
                                <h3>{title}</h3>
                            </div>
                            <p>
                                LittleBIG studio s.r.o.<br></br>
                                Jagellonská 895/9<br></br>
                                Vinohrady - Praha 3<br></br>
                                130 00
                            </p>
                            <p>{companyId} 07565330<br></br>
                            {taxId} CZ07565330</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                        <div className="widget about-widget">
                            <div className="widget-title">
                                <h3></h3>
                            </div>
                                <p>
                                {phoneNumber}<br></br>
                                +420 702 162 829
                                </p>
                                <p>{email}<br></br>
                                <a href="mailto:produkce@littlebigstudio.cz">produkce@littlebigstudio.cz</a></p>
                                <p>{openingHoursLabel}<br></br>
                                {openingHoursText}</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                    </div>
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                        <div className="widget about-widget" id="socials-icons">
                            <div className="widget-title">
                                <h3></h3>
                            </div>
                                
                                <a href="https://www.facebook.com/littlebigstudioprague" target="_blank"><img src={fb} className="socials-icon"></img></a>
                                <span>   </span>
                                <a href="https://www.instagram.com/littlebigstudioprague?igsh=MW9pZHNsdHdsbnVpNg==" target="_blank"><img src={ig} className="socials-icon"></img></a>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpperFooter;
import React, { useEffect, useState } from "react";
import HeroSlide from "./HeroSlide";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import "./HeroSlider.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import "./HeroSlider.css"

import bg1 from "../../assets/little-big-studio/interior-pictures/MAR4396.jpg";
import bg2 from "../../assets/little-big-studio/interior-pictures/MAR4298.jpg";
import bg3 from "../../assets/little-big-studio/interior-pictures/MAR4302.jpg";
import bg4 from "../../assets/little-big-studio/interior-pictures/MAR4304.jpg";
import bg5 from "../../assets/little-big-studio/interior-pictures/MAR4286.jpg";
import { SiteLanguage } from "../../utils/enums/SiteLanguage";
import { languageMutations } from "../../utils/constants/language-mutations";

interface HeroSliderProps {
    language: SiteLanguage
}

const HeroSlider: React.FC<HeroSliderProps> = ({ language }) => {
    const btnText = "";

    let size = 0.5;

    if (window.innerWidth > 800) {
        size = 0.55;
    }

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth > 1200 ? 1200 : window.innerWidth * size * 1.5,
        height: window.innerWidth > 1200 ? 924 : window.innerWidth * size,
    });

    console.log(dimensions);

    // Function to handle window resize
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth > 1200 ? 1200 : window.innerWidth * size * 1.5,
            height: window.innerWidth > 1200 ? 924 : window.innerWidth * size,
        });
    };

    useEffect(() => {
        // Add event listener to handle window resizing
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let title = languageMutations.cz.whereItAllBegins

    if (language === SiteLanguage.English) {
        title = languageMutations.en.whereItAllBegins;
    }
    if (language === SiteLanguage.Chinese) {
        title = languageMutations.zh.whereItAllBegins;
    }

    return (
        <section className="hero-slider hero-style-2" id="kde-vse-vznika">
            <div className="container">
            <div className="row">
                    <div className="col col-lg-6 col-md-8 col-sm-10">
                        <div className="section-title-s3">
                            
                        </div>
                        <h2>{title}</h2>
                    </div>
                </div>
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                loop={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={1}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    600: {
                        slidesPerView: 1,
                        spaceBetween: 80
                    },
                    1000: {
                        slidesPerView: 1,
                        spaceBetween: 80
                    },
                    1900: {
                        slidesPerView: 1,
                        spaceBetween: 40
                    }
                }}
                className="swiper-container"
            >
                <SwiperSlide>
                    <HeroSlide
                        background={bg1}
                        dataText=""
                        titleText=""
                        slideText=""
                        buttonText={btnText}
                        buttonLink=""
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlide
                        background={bg2}
                        dataText=""
                        titleText=""
                        slideText=""
                        buttonText={btnText}
                        buttonLink=""
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlide
                        background={bg3}
                        dataText=""
                        titleText=""
                        slideText=""
                        buttonText={btnText}
                        buttonLink=""
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlide
                        background={bg4}
                        dataText=""
                        titleText=""
                        slideText=""
                        buttonText={btnText}
                        buttonLink=""
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlide
                        background={bg5}
                        dataText=""
                        titleText=""
                        slideText=""
                        buttonText={btnText}
                        buttonLink=""
                    />
                </SwiperSlide>
                
            </Swiper>
            </div>
        </section>
    );
}

export default HeroSlider;
import React from "react";

interface NavbarHeaderProps {
    logo: string;
}

const NavbarHeader: React.FC<NavbarHeaderProps> = ({ logo }) => {
    return (
        <div className="navbar-header">
            <a className="navbar-brand" href="/"><img src={logo} alt="logo"/></a>
            <button type="button" className="open-btn">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
            </button>
        </div>
    )
}

export default NavbarHeader;
import NavbarHeader from "./NavbarHeader"
import HeaderSearch from "../HeaderSearch";

import logoWhite from "../../../assets/little-big-studio/logo.png";
import logoBlack from "../../../assets/little-big-studio/logo-black.png" 
import LogoutButton from "../../Login/LogoutButton";
import { useLocation } from "react-router-dom";
import { SiteLanguage } from "../../../utils/enums/SiteLanguage";
import { languageMutations } from "../../../utils/constants/language-mutations";

interface NavbarProps {
    language: SiteLanguage
    toggleCzech: () => void;
    toggleEnglish: () => void;
    toggleChinese: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ language, toggleCzech, toggleEnglish, toggleChinese }) => {

    // Get the current location (URL path)
    const location = useLocation();

    const items = {
        whatWeDo: "Co děláme",
        whatWeFilmed: "Co jsme točili",
        whereItAllBegins: "Kde vše vzniká",
        blog: "Blog",
        contacts: "Kontakty",
    }

    if (language === SiteLanguage.English) {
        const t = languageMutations.en;
        items.whatWeDo = t.whatWeDo;
        items.whatWeFilmed = t.whatWeFilmed;
        items.whereItAllBegins = t.whereItAllBegins;
        items.contacts = t.contacts;
    }

    if (language === SiteLanguage.Chinese) {
        const t = languageMutations.zh;
        items.whatWeDo = t.whatWeDo;
        items.whatWeFilmed = t.whatWeFilmed;
        items.whereItAllBegins = t.whereItAllBegins;
        items.blog = t.blog
        items.contacts = t.contacts;
    }

    let logo = logoWhite;

    // Check if the current path is the home page ("/")
    const isHomePage = location.pathname === "/" || (location.hash.includes("#"));

    const NavbarItems: React.FC = () => {
        if (isHomePage) {
            return (
                <ul className="nav navbar-nav">
                        <li><a href="#co-delame">{items.whatWeDo}</a></li>
                        <li><a href="#co-jsme-tocili">{items.whatWeFilmed}</a></li>
                        <li><a href="#kde-vse-vznika">{items.whereItAllBegins}</a></li>
                        <li><a href="/blog">{items.blog}</a></li>
                        <li><a href="#kontakty">{items.contacts}</a></li>
                        <li id="language-changers">
                            <a id="lch1" href="#" onClick={toggleCzech} className="language-changer">CZ</a>
                            <a id="lch2" href="#" onClick={toggleEnglish} className="language-changer">EN</a>
                            <a id="lch3" href="#" onClick={toggleChinese} className="language-changer">ZH</a></li>
                        <LogoutButton />
                    </ul>
            )
        }
        else {
            return (
                <ul className="nav navbar-nav">
                        <li><a href="/#co-delame">{items.whatWeDo}</a></li>
                        <li><a href="/#co-jsme-tocili">{items.whatWeFilmed}</a></li>
                        <li><a href="/#kde-vse-vznika">{items.whereItAllBegins}</a></li>
                        <li><a href="/blog">{items.blog}</a></li>
                        <li><a href="/#kontakty">{items.contacts}</a></li>
                        <li>
                            <div className="language-buttons">
                                <button onClick={toggleCzech} className="language-changer">CZ</button>
                                <button onClick={toggleEnglish} className="language-changer">EN</button>
                                <button onClick={toggleChinese} className="language-changer">ZH</button>
                            </div>
                        </li>
                        <LogoutButton />
                    </ul>
            )
        }
    }

    if (!isHomePage) {
        logo = logoBlack;
    }

    return (
        <nav className="navigation navbar navbar-default">
            <div className="container">
                <NavbarHeader logo={logo}/>
                <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                <button className="close-navbar"><i className="ti-close"></i></button>
                    <NavbarItems />
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
import React from "react";
import { BlogEntryProps } from "../../../utils/types/BlogEntryItem";
import BlogEntryMeta from "../BlogEntryMeta";
import BlogText from "./BlogText";
import AuthorBox from "./AuthorBox";
import BlogButtons from "./BlogButtons";
import "./BlogSingleSection.css";

const BlogSingleSection: React.FC<BlogEntryProps> = ( entry ) => {
    const name = `${
        entry.entry.blog.title
            .toLocaleLowerCase()
            .replaceAll(" ", "-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        }`
    const urlId = `${entry.entry.id}/${name}`;
    return (
        <section className="blog-single-section section-padding">
            <div className="container">
            <div className="row">
                <div className="col col-md-10 col-md-offset-1">
                    <div className="blog-content">
                        <div className="post format-standard-image">
                            <div className="entry-media">
                                <img id="blog-image" src={entry.entry.blog.images![0].url} alt=""/>
                            </div>
                            <div className="date-entry-meta">
                                <h1>{entry.entry.blog.title}</h1>
                                {/* <div className="cat">{entry.entry.blog.category}</div> */}
                                <BlogEntryMeta entry={entry.entry.meta} id={entry.entry.id} urlId={urlId}/>
                            </div>
                            <BlogText blog={entry.entry.blog.content} />
                            {/* share */}
                            {/* <div className="tag-share clearfix">
                                <div className="tag">
                                    <ul>
                                        <li><a href="#">Family</a></li>
                                        <li><a href="#">Lawyer</a></li>
                                        <li><a href="#">Bradning</a></li>
                                    </ul>
                                </div>
                                <div className="share">
                                    <ul>
                                        <li><a href="#"><i className="ti-facebook"></i></a></li>
                                        <li><a href="#"><i className="ti-twitter-alt"></i></a></li>
                                        <li><a href="#"><i className="ti-linkedin"></i></a></li>
                                        <li><a href="#"><i className="ti-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <AuthorBox author={entry.entry.meta.author}/> */}
                            {/* next and previous buttons */}
                            <BlogButtons nextId={entry.nextId!} prevId={entry.prevId!}/>
                            {/* comments */}
                            {/* <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">5 Comments</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src="assets/images/blog-details/comments-author/img-1.jpg" alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>Jhon <span className="comments-date">says Jan 22, 2020 at 9:00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>Hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer gregor then</p>
                                                            <div className="comments-reply">
                                                                <a className="comment-reply-link" href="#"><span>Reply</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src="assets/images/blog-details/comments-author/img-2.jpg" alt="" /></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                <h4>Jhon <span className="comments-date">says Jan 22, 2020 at 9:00am</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>Hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer gregor then</p>
                                                                    <div className="comments-reply">
                                                                        <a  className="comment-reply-link" href="#"><span>Reply</span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src="assets/images/blog-details/comments-author/img-3.jpg" alt="" /></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>Jhon <span className="comments-date">says Jan 22, 2020 at 9:00am</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>Hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer gregor then</p>
                                                                            <div className="comments-reply">
                                                                                <a  className="comment-reply-link" href="#"><span>Reply</span></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="comment">
                                            <div>
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src="assets/images/blog-details/comments-author/img-1.jpg" alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>Jhon <span className="comments-date">says Jan 22, 2020 at 9:00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>Hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer gregor then</p>
                                                            <div className="comments-reply">
                                                                <a  className="comment-reply-link" href="#"><span>Reply</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>

                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Add your comment</h3>
                                    <form method="post" id="commentform" className="comment-form">
                                        <div className="form-textarea">
                                            <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                        </div>
                                        <div className="form-inputs">
                                            <input placeholder="Website" type="url" />
                                            <input placeholder="Name" type="text" />
                                            <input placeholder="Email" type="email" />
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Post Comment" type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default BlogSingleSection;
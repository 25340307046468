import React from 'react';
import '../assets/css/animate.css';
import '../assets/css/bootstrap-touchspin.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/flaticon.css';
// import './assets/css/font-awesome.min.css';
import "../assets/css/jquery.fancybox.css";
import '../assets/css/magnific-popup.css';
import '../assets/css/odometer-theme-default.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/css/owl.carousel.css';
import '../assets/css/owl.theme.css';
import '../assets/css/owl.transitions.css';
import "../assets/css/owl.css";
import '../assets/css/slick-theme.css';
import '../assets/css/slick.css';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
import '../assets/css/swiper.min.css';
import '../assets/css/themify-icons.css';

const Imports: React.FC = () => {
    return (
        <></>
    )
}

export default Imports;
import React from "react";
import PageTitle from "../components/PageTitle";
import BlogForm from "../components/BlogPage/SingleBlog/BlogForm";
import "./AddBlogPage.css";
import EditContentForm from "../components/Content/EditContentForm";
import { useParams } from "react-router-dom";

const EditContentPage: React.FC = () => {
    const title = useParams().title!;
    return (
        <div>
            <PageTitle pageName="" desc="" id="add-blog"/>
            <section className="blog-pg-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-md">
                            <div className="blog-content">
                                <EditContentForm itemUrl={title}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EditContentPage;
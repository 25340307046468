import React from "react";
import { BlogEntryProps } from "../../utils/types/BlogEntryItem";
import BlogEntryMeta from "./BlogEntryMeta";
import { Swiper, SwiperSlide } from "swiper/react";

const BlogEntryGallery: React.FC<BlogEntryProps> = ({ entry }) => {
    const urlBase = "/aktuality"
    const urlId = `${entry.id}/${entry.blog.title.toLocaleLowerCase().replaceAll(" ", "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`;
    const url = `${urlBase}/${urlId}`
    return (
        <div className="post format-gallery">
            <Swiper
                loop={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={1}
                navigation={true}
                >
                <div className="entry-media post-slider">
                    {entry.blog.images!.map((item, index) => (
                        <SwiperSlide key={index}>
                            <img src={item.url} alt={item.alt}></img>
                        </SwiperSlide>
                        ))}
                </div>
            </Swiper>
            <div className="entry-details">
                <div className="cat">{entry.blog.category}</div>
                <h3><a href={url}>{entry.blog.title}</a></h3>
                <p>{entry.blog.description}</p>
                <a href={url} className="read-more">Read More</a>
            </div>
            <BlogEntryMeta entry={entry.meta} id={entry.id} urlId="urlId"/>
        </div>
    );
}

export default BlogEntryGallery;
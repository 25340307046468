import { isLoggedIn } from "../../utils/types/User"

const LogoutButton: React.FC = () => {
    if (isLoggedIn()) {
        return (
            <li><a href="/login"><i className="ti-close"></i></a></li>
        )
    }
    else {
        return <></>
    }
}

export default LogoutButton;
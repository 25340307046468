import React from "react";
import { BlogEntryProps } from "../../utils/types/BlogEntryItem";
import BlogEntryMeta from "./BlogEntryMeta";
import "./BlogEntryImage.css"

const BlogEntryImage: React.FC<BlogEntryProps> = ( { entry, inList } ) => {
    const urlBase = "/blog"
    const urlId = `${entry.id}/${entry.blog.title.toLocaleLowerCase().replaceAll(" ", "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`;
    return (
        <div className="post format-standard-image">
            <div className="entry-media">
                <img id="blog-image-thumbnail" src={entry.blog.images![0].url} alt={entry.blog.images![0].alt}/>
            </div>
            <div className="entry-details">
                {/* <div className="cat">{entry.blog.category}</div> */}
                <h3><a href={`${urlBase}/${urlId}`}>{entry.blog.title}</a></h3>
                <p>{entry.blog.description}</p>
                {inList && <a href={`${urlBase}/${urlId}`} className="read-more">Read More</a>}
            </div>
            <BlogEntryMeta entry={entry.meta} id={entry.id} urlId={urlId}/>
        </div>
    );
}

export default BlogEntryImage;
import React from "react";
import { BlogEntryProps } from "./types/BlogEntryItem";
import BlogEntry from "../components/BlogPage/BlogEntry";
import BlogEntryImage from "../components/BlogPage/BlogEntryImage";
import BlogEntryGallery from "../components/BlogPage/BlogEntryGallery";
import BlogEntryQuote from "../components/BlogPage/BlogEntryQuote";
import BlogEntryVideo from "../components/BlogPage/BlogEntryVideo";
import { BlogThumbnailType } from "./enums/BlogThumbnailType";

interface BlogTypeMapperProps {
    item: BlogEntryProps;
}

const BlogTypeMapper: React.FC<BlogTypeMapperProps> = ({ item }) => {
    switch (item.thumbnailType) {
        case BlogThumbnailType.Simple:
            return (
                <BlogEntry entry={item.entry} inList={item.inList} />
            );
        case BlogThumbnailType.Image:
            return (
                <BlogEntryImage entry={item.entry} inList={item.inList} />
            );
        case BlogThumbnailType.Gallery:
            return (
                <BlogEntryGallery entry={item.entry} inList={item.inList} />
            );
        case BlogThumbnailType.Quote:
            return (
                <BlogEntryQuote entry={item.entry} inList={item.inList} />
            );
        case BlogThumbnailType.Video:
            return (
                <BlogEntryVideo entry={item.entry} inList={item.inList} />
            );
        default:
            return null;
    }
}

export default BlogTypeMapper;
export const languageMutations = {
    cz: {
        whatWeDo: "Co děláme",
        whatWeFilmed: "Co jsme točili?",
        whereItAllBegins: "Kde vše vzniká",
        blog: "Blog",
        contacts: "Kontakty",
        creativeAgency: "KREATIVNÍ AGENTURA",
        production: "PRODUKCE",
        postProduction: "POST-PRODUKCE",
        soundStudio: "ZVUKOVÉ STUDIO",
        about: `<p>Naše profesionální společnost se&nbsp;specializuje na&nbsp;kompletní služby v&nbsp;oblasti videoprodukce. Od&nbsp;preprodukce až po&nbsp;finální mastering.</p>
            <p>Nabízíme komplexní filmová produkce a&nbsp;postprodukce, včetně color gradingu, střih videa a DIT zpracování dat. Zajišťujeme také datamanagement pro audio a video, zvuková postprodukce, nahrávání zvuku a ruchů.
            Specializujeme&nbsp;se na&nbsp;natáčení filmů, reklam, eventů a&nbsp;videí pro&nbsp;sociální sítě.  
            V&nbsp;naší&nbsp;prezentační video místnosti můžete pohodlně prohlížet a hodnotit výsledky naší práce.</p>
            <p>Nabízíme také tvorbu zvukové podcasty, animace a motion grafika (pohybová grafika). 
                Ať&nbsp;už&nbsp;potřebujete video z&nbsp;dronu, nebo profesionální reklamní spot, naše služby jsou vám plně k&nbsp;dispozici.</p>
            <p>Pro&nbsp;vaše&nbsp;projekty máme k&nbsp;dispozici půjčovnu kamerové techniky v&nbsp;Praze 
            a v&nbsp;Plzni.</p>`,
        email: "Email:",
        phoneNumber: "Telefonní číslo:",
        openingHoursLabel: "Pracovní doba:",
        openingHoursText: "Pondělí - Pátek 9:00 - 18:00",
        companyId: "IČO:",
        taxId: "DIČ:",
        whoWeAre: "Kdo jsme?"
    },
    en: {
        whatWeDo: "What We Do?",
        whatWeFilmed: "What We've Filmed",
        whereItAllBegins: "Where It All Begins",
        blog: "Blog",
        contacts: "Contacts",
        creativeAgency: "CREATIVE AGENCY",
        production: "PRODUCTION",
        postProduction: "POST-PRODUCTION",
        soundStudio: "SOUND STUDIO",
        about: `<p>Our professional company specializes in providing comprehensive services in
        video production, from pre-production to final mastering.</p>
        <p>We offer full-scale film production and post-production services, including
        color grading, video editing, and DIT data processing. We also provide data
        management for audio and video, sound post-production, sound recording,
        and sound effects. We specialize in filming movies, commercials, events, and
        social media videos. In our presentation video room, you can comfortably
        review and evaluate the results of our work.</p>
        <p>We also offer sound podcast creation, animation, and motion graphics.
        Whether you need drone footage or a professional commercial spot, our
        services are fully available to you.</p>
        <p>For your projects, we have camera equipment rental services in Prague and
        Pilsen.</p>`,
        email: "Email:",
        phoneNumber: "Phone Number:",
        openingHoursLabel: "Working Hours:",
        openingHoursText: "Monday - Friday 9:00AM - 6:00PM",
        companyId: "Company ID (IČO):",
        taxId: "Tax ID (DIČ):",
        whoWeAre: "Who we are?"

    },
    zh: {
        whatWeDo: "我們的服務",
        whatWeFilmed: "我們的作品",
        whereItAllBegins: "一切的開始",
        blog: "部落格",
        contacts: "聯絡方式",
        creativeAgency: "創意集思團隊",
        production: "影片製作",
        postProduction: "影片後期製作",
        soundStudio: "錄音工作室",
        about: `<p>我們專注於提供完整的影像製作服務，涵蓋前期製作到最終的成片製作。</p>
        <p>我們提供全方位的電影製作和後期製作服務，包括調色、影片剪輯和數據管理（DIT）
        處理。我們也提供影音數據管理、聲音後期製作、錄音及音效製作。我們專門拍攝電
        影、廣告、活動及社群媒體影片。在我們的影片室裡，您可以舒適地檢視並評估我們
        的作品成果。</p>
        <p>此外，我們還提供聲音播客製作、動畫及視覺特效合成/動態圖形特效服務。不論您需
        要無人機拍攝還是專業的商業廣告片，我們的服務隨時為您提供。</p>
        <p>另外，我們在布拉格和皮爾森提供攝影器材租賃服務</p>`,
        email: "電子郵件",
        phoneNumber: "電話號碼",
        openingHoursLabel: "營業時間",
        openingHoursText: "週一至週五 上午9:00 - 下午6:00",
        companyId: "公司編號 (IČO):",
        taxId: "稅號 (DIČ):",
        whoWeAre: "我們的團隊"
    }
}
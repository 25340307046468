import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Header from './components/Header/Header';
import HomePage from './Pages/HomePage';
import NotFound from './components/Errors/NotFound';

import BlogPage from './Pages/BlogPage';

import Imports from './utils/Imports';
import SingleBlogPage from './Pages/SingleBlogPage';
import AddBlogPage from './Pages/AddBlogPage';
import LoginPage from './Pages/LoginPage';
import EditBlogPage from './Pages/EditBlogPage';
import EditContentPage from './Pages/EditContentPage';
import Unauthorized from './components/Errors/Unauthorized';
import { SiteLanguage } from './utils/enums/SiteLanguage';
import { useState } from 'react';
import "./App.css";

function App() {
  const [language, setLanguage] = useState<SiteLanguage>(SiteLanguage.Czech); // Default language is Czech

  // Function to switch languages
  const toggleCzech = () => {
    setLanguage(SiteLanguage.Czech);
    console.log('Language switched to:', language);
  };
  const toggleEnglish = () => {
    setLanguage(SiteLanguage.English);
    console.log('Language switched to:', language);
  };
  const toggleChinese = () => {
    setLanguage(SiteLanguage.Chinese);
    console.log('Language switched to:', language);
  };

  return (
    <Router>
      <Imports />
      <div className="">
        <Header
          language={language} 
          toggleCzech={toggleCzech}
          toggleEnglish={toggleEnglish}
          toggleChinese={toggleChinese}
        />
        <Routes>
          <Route path="/" element={<HomePage language={language} />} />
          <Route path="/index" element={<HomePage language={language} />} />
          <Route path="*" element={<NotFound />}/>
          <Route path="/blog" element={<BlogPage language={language} />}/>
          <Route path="/blog/:id/:title" element={<SingleBlogPage language={language} />}/>
          <Route path="/add-blog" element={<AddBlogPage />}/>
          <Route path="/edit-blog/:id/:title" element={<EditBlogPage />}/>
          <Route path="/edit-content/:title" element={<EditContentPage />}/>
          <Route path="/unauthorized" element={<Unauthorized />}/>
          <Route path="/login" element={<LoginPage />}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import PageTitle from "../components/PageTitle";
import AddBlog from "../components/BlogPage/SingleBlog/BlogForm";
import "./AddBlogPage.css";
import { useParams } from "react-router-dom";

const EditBlogPage: React.FC = () => {
    const blogId = useParams().id;
    let id;
    if (blogId !== undefined || blogId !== null) {
        id = (blogId as unknown) as number;
    }
    return (
        <div>
            <PageTitle pageName="" desc="" id="add-blog"/>
            <section className="blog-pg-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-md">
                            <div className="blog-content">
                                <AddBlog id={id}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default EditBlogPage;
import React, { useEffect, useRef, useState } from "react";
import { Image } from "../../../utils/types/Image";
import "./ServiceSectionGridItem.css";

interface ServiceSectionGridItemProps {
    img: Image;
    link: string;
    title: string;
}

const ServiceSectionGridItem: React.FC<ServiceSectionGridItemProps> = ({ img, link, title }) => {
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const handleVideoOpen = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsVideoVisible(true);
    };

    const handleCloseVideo = () => {
        setIsVideoVisible(false);
    };

    const handleVideoClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (isVideoVisible && videoRef.current) {
            const videoElement = videoRef.current;
            videoElement.autoplay = true;
            videoElement.muted = true;
            videoElement.play().catch((err) => {
                console.error("Failed to play video:", err);
            });
        }
    }, [isVideoVisible]);

    return (
        <div className="grid-item">
            {/* Image and title that triggers the popup */}
            <a href="/" onClick={handleVideoOpen}>
                <img src={img.url} alt={img.alt} />
                <h3>{title}</h3>
            </a>

            {/* Video modal popup */}
            {isVideoVisible && (
                <div className="video-overlay" onClick={handleCloseVideo}>
                    <div className="video-popup" onClick={handleVideoClick}>
                        {/* <button className="close-btn" onClick={handleCloseVideo}>
                            &times;
                        </button> */}
                        <video
                            ref={videoRef}
                            src={link}
                            playsInline
                            autoPlay
                            muted
                            preload="auto"
                            loop
                            controls
                        >
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceSectionGridItem;

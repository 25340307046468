import React from "react";

interface BlogTextParams {
    blog: string;
}

const BlogText: React.FC<BlogTextParams> = ({ blog }) => {
    return (
        <div dangerouslySetInnerHTML={{__html: blog}}></div>
    )
}

export default BlogText;
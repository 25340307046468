import React from "react";
import "./SingleContentSection.css";
import { isLoggedIn } from "../../utils/types/User";
import pic from "../../assets/little-big-studio/zeskrine_banner.jpg";

interface SingleContentSectionProps {
    title: string;
    text: string;
    itemUrl?: string;
}

interface EditButtonProps {
    itemUrl?: string;
    id?: number;
}

const SingleContentSection: React.FC<SingleContentSectionProps> = ({ title, text, itemUrl }) => {
    return (
        <section className="service-single-section section-padding" id="ze-skrine">
            <a href="https://www.zeskrine.cz" target="_blank">
                <img src={pic} alt="" />
            </a>
        </section>
    );
}

const EditButton: React.FC<EditButtonProps> = ({ itemUrl }) => {
    if (!itemUrl) {
        itemUrl = "";
    }
    else {
        itemUrl = `/edit-content/${itemUrl}`
    }
    if (isLoggedIn())
    return (
        <div>
            <a href={itemUrl}><button className="theme-btn">Edit</button></a>
            {/* <a href="/add-content"><button className="theme-btn">Add</button></a> */}
        </div>
    )
    else {
        return <></>
    };
}

export default SingleContentSection;
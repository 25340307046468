import React, {useEffect, useRef} from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import Navbar from "./Navbar/Navbar";
import { SocialMediaLink } from "../../utils/types/SocialMediaLink";
import "./Header.css";
import { SiteLanguage } from "../../utils/enums/SiteLanguage";

interface HeaderProps {
    language: SiteLanguage;
    toggleCzech: () => void;
    toggleEnglish: () => void;
    toggleChinese: () => void;
}

const Header: React.FC<HeaderProps> = ({ language, toggleCzech, toggleEnglish, toggleChinese }) => {
    const links: SocialMediaLink[] = [
        { url: "https://www.facebook.com/", icon: "" }
    ];
    
    const videoRef=useRef<HTMLVideoElement | null>(null);

    // Get the current location (URL path)
    const location = useLocation();
    useEffect(() => {
        if (!videoRef.current) {
            // alert("Can not find video")
            return;
        }
        videoRef.current?.setAttribute("muted", "");
        const x = videoRef.current 
        if (x){
            x.autoplay = true
            x.muted = true
            // videoRef.current?.play()
            setTimeout(function() {
                // x.play();
            }, 2000);     
        }
    }, []);

    // Check if the current path is the home page ("/")
    const isHomePage = ((location.pathname === "/" || location.pathname === "/index") || (location.hash.includes("#")));

    return (
        <div>
            <header id="header" className="site-header header-style-1">
                {/* <TopBar phoneNumber={"123 456 789"} address={"adresa"} email={"example@email.com"} links={links} /> */}
                <Navbar
                    language={language} 
                    toggleCzech={toggleCzech}
                    toggleEnglish={toggleEnglish}
                    toggleChinese={toggleChinese}
                />
            </header>
            <div className="container"  id="video-container">
                {/* Conditionally render the video only on the home page */}
                {isHomePage && (
                    <div className="video-wrapper">
                        <video src="https://visionwebmedia.cz/uploads/lbs/edit.mov" ref={videoRef} playsInline autoPlay={true} muted={true} preload="true" loop>
                        </video>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;

import React from "react";
import { BlogEntryProps } from "../../utils/types/BlogEntryItem";
import BlogEntryMeta from "./BlogEntryMeta";

const BlogEntry: React.FC<BlogEntryProps> = ({ entry }) => {
    const urlBase = "/blog"
    const urlId = `${entry.id}/${entry.blog.title.toLocaleLowerCase().replaceAll(" ", "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`;
    const url = `${urlBase}/${urlId}`
    return (
        <div className="post format-standard">
            <div className="entry-details">
                <div className="cat">{entry.blog.category}</div>
                <h3><a href={url}>{entry.blog.title}</a></h3>
                <p>{entry.blog.description}</p>
                <a href={url} className="read-more">Read More</a>
            </div>
            <BlogEntryMeta entry={entry.meta} id={entry.id} urlId="url"/>
        </div>
    )
}

export default BlogEntry;
import React from "react";
import "./HeroSlide.css"; // Import the CSS file for styling

interface HeroSlideProps {
    background: string;
    dataText: string;
    titleText: string;
    slideText: string;
    buttonText?: string;
    buttonLink?: string;
}

interface SlideButtonProps {
    buttonText?: string;
    buttonLink?: string;
}

const HeroSlide: React.FC<HeroSlideProps> = ({ background, dataText, titleText, slideText, buttonText, buttonLink }) => {
    return (
        <div className="slide-inner">
            <div className="slide-bg-image" style={{ backgroundImage: `url(${background})` }}>
                <div className="slide-content">
                    <div data-swiper-parallax="300" className="slide-title">
                        <h1>{titleText}</h1>
                    </div>
                    <div
                        data-swiper-parallax="400"
                        className="slide-text"
                        dangerouslySetInnerHTML={{ __html: slideText }}
                    ></div>
                    <SlideButton buttonLink={buttonLink} buttonText={buttonText} />
                </div>
            </div>
        </div>
    );
};

const SlideButton: React.FC<SlideButtonProps> = ({ buttonText, buttonLink }) => {
    if (buttonText) {
        return (
            <div data-swiper-parallax="500" className="slide-btns">
                <a href={buttonLink} className="theme-btn">
                    {buttonText}
                </a>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default HeroSlide;

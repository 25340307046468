import React, { useEffect, useState } from "react";
import FeatureItem from "./FeatureItem";
import "./FeatureSection.css";
import zvukStudioIcon from "../../assets/little-big-studio/icons/zvukove-studio.png";
import produkceIcon from "../../assets/little-big-studio/icons/klapka.png";
import postProductionIcon from "../../assets/little-big-studio/icons/post-produkce.png";
import creativAgenturaIcon from "../../assets/little-big-studio/icons/kreativAgency.png";
import { useCrud } from "../../hooks/CrudHook";
import DbBlog from "../../utils/types/DbBlog";
import MapBlogFromDbToUi from "../../utils/mappers/BlogMapper";
import { BlogEntryItem } from "../../utils/types/BlogEntryItem";
import { SiteLanguage } from "../../utils/enums/SiteLanguage";
import { languageMutations } from "../../utils/constants/language-mutations";

interface FeatureSectionProps {
    language: SiteLanguage
}

const FeatureSection: React.FC<FeatureSectionProps> = ({language}) => {
    const { data, loading } = useCrud<DbBlog>({ endpoint: "articles" });
    const [filteredBlogs, setFilteredBlogs] = useState<BlogEntryItem[]>([]);

    useEffect(() => {
        if (!loading && data && data.length > 0) {
            const sortedBlogs = MapBlogFromDbToUi(
                data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            );
            const mainContentBlogs = sortedBlogs.filter(b => b.blog.category === 'mainContent');
            setFilteredBlogs(mainContentBlogs);
        }
    }, [data, loading]);

    if (loading) {
        return <div>Loading ...</div>;
    }

    const findBlogByUrl = (url: string) => {
        return filteredBlogs.find(i => i.meta.url === url) || { blog: { title: "", description: "" } };
    };

    const creativeAgency = {
        image: {
            url: creativAgenturaIcon,
            alt: "creative-agency-image"
        },
        link: {
            url: "/",
            linkName: ""
        },
        item: {
            blog: {
                title: "KREATIVNÍ AGENTURA",
                description: ""
            }
        }
    };

    const production = {
        image: {
            url: produkceIcon,
            alt: "production-image"
        },
        link: {
            url: "",
            linkName: ""
        },
        item: {
            blog: {
                title: "PRODUKCE",
                description: ""
            }
        }
    };

    const postProduction = {
        image: {
            url: postProductionIcon,
            alt: "post-production-image"
        },
        link: {
            url: "",
            linkName: ""
        },
        item: {
            blog: {
                title: "POST-PRODUKCE",
                description: ""
            }
        }
    };

    const soundStudio = {
        image: {
            url: zvukStudioIcon,
            alt: "sound-studio-image"
        },
        link: {
            url: "",
            linkName: ""
        },
        item: {
            blog: {
                title: "ZVUKOVÉ STUDIO",
                description: ""
            }
        }
    };

    if (language === SiteLanguage.English)
        {
            const t = languageMutations.en;
            creativeAgency.item.blog.title = t.creativeAgency;
            production.item.blog.title = t.production;
            postProduction.item.blog.title = t.postProduction;
            soundStudio.item.blog.title = t.soundStudio;
        }
    
        if (language === SiteLanguage.Chinese)
        {
            const t = languageMutations.zh;
            creativeAgency.item.blog.title = t.creativeAgency;
            production.item.blog.title = t.production;
            postProduction.item.blog.title = t.postProduction;
            soundStudio.item.blog.title = t.soundStudio;
        }

    return (
        <section className="feature-section section-padding">
            <div className="container">
                <div className="row" id="feature-icons">
                    <FeatureItem header={creativeAgency.item.blog.title} image={creativeAgency.image} link={creativeAgency.link} text={creativeAgency.item.blog.description} className=""/>
                    <FeatureItem header={production.item.blog.title} image={production.image} link={production.link} text={production.item.blog.description} className="bordered"/>
                    <FeatureItem header={postProduction.item.blog.title} image={postProduction.image} link={postProduction.link} text={postProduction.item.blog.description} className=""/>
                    <FeatureItem header={soundStudio.item.blog.title} image={soundStudio.image} link={soundStudio.link} text={soundStudio.item.blog.description} className="bordered"/>
                </div>
            </div>
        </section>
    );
};

export default FeatureSection;
